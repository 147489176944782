<template>
  <div class="iframe-container">
    <iframe
      src="https://bcpi-qr-map-dd663f75a2af.herokuapp.com/"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>
<style>
.iframe-container iframe {
  width: 100%;
  height: 1000px;
  border: none;
}
</style>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
